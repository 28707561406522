import { useContext, createContext } from 'react';

export const userContext = createContext(null);

export interface IUser {
	first_name: string;
	last_name: string;
	language: string;
	user_id: string;
	email: string;
	org: string;
	org_id: string;
	is_access_platform: boolean;
	can_approve: boolean;
	can_manage_user: boolean;
	roles: string;
}

interface IUserContext {
	user: IUser;
}

export function useUserContext(): IUserContext {
	return useContext(userContext) as unknown as IUserContext;
}

export default userContext;
