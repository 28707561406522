import React, { useState, useEffect, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastProvider } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
// components & routes
import Routes from './Routes';
import GlobalError from 'ui/pages/GlobalError';
import Loader from 'ui/components/Loader/Loader';
import Sidebar from 'ui/components/Nav/Sidenav/Sidenav';
import ClearToasts from 'ui/components/ClearToasts/ClearToasts';
// contexts
import ModalContext from 'src/libs/ModalContext';
import { userContext } from 'src/libs/contextLib';
import { sideNavContext } from 'src/libs/SidenavContext';
import ModalProvider from 'src/libs/ModalProvider';
import NotificationContext from 'src/libs/NotificationContext';
import NotificationProvider from 'src/libs/NotificationProvider';
import ReferenceListProvider from 'src/libs/ReferenceListProvider';
// helpers
import _ from 'lodash';
import moment from 'moment';
import { LicenseManager } from 'ag-grid-enterprise';
import {
	getOrg,
	checkExpiredToken,
	handleLogout,
	getUserConnected,
} from 'ui/modules/Authentication/utils/authentication.helper';
import { getOrganizationCachedSettings } from 'src/utils/dataUtils';
// Types
import { IBusinessCategory } from 'ui/modules/Core/types/interfaces/business-category.interface';
import { sidenavClassNameType } from 'ui/components/Nav/Sidenav/types/interfaces/sidenav.interface';
// constants
import { sidenavKeyStorage } from 'ui/components/Nav/Sidenav/constants/sidenav.constant';
// services
import { getMyPermissions } from 'ui/modules/Core/services/user.service';
import { retrieveOrgsService } from 'ui/modules/Authentication/services/auth.service';
import { getRuleset } from 'ui/modules/OrganizationSettings/services/services.references';
import { getBusinessCategoryService } from 'ui/modules/Core/services/business-category.service';
// react-ace dependencies to avoid crash app
import 'ace-builds/src-noconflict/ace';
// CSS & stylesheets
import 'bootstrap/dist/css/bootstrap.min.css';
import './ui/stylesheets/variables.sass';
import './ui/stylesheets/AgGrid.sass';
import './ui/stylesheets/Form.sass';
import 'antd/dist/antd.css';

LicenseManager.setLicenseKey((import.meta as any).env.VITE_REACT_APP_AG_GRID_LICENSE_KEY);

const ModalConsumer = ModalContext.Consumer;
const NotificationConsumer = NotificationContext.Consumer;
// Makes sure we have the correct locale everywhere
moment.tz.setDefault(moment.tz.guess());
// Get last sidenav state from local storage
const lastSidenavClassName = (localStorage.getItem(sidenavKeyStorage) || 'sidenav-collapsed') as sidenavClassNameType;

const App = () => {
	const history = useHistory();
	const { t: translate } = useTranslation();
	const [user, setUser] = useState({});
	const [userInitials, setInitials] = useState('');
	const [userRigths, setUserRights] = useState(null);
	const [myPermissions, setMyPermissions] = useState(null);
	const [isAuthenticated, userHasAuthenticated] = useState(false);
	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const [isLoadingPermission, setIsLoadingPermission] = useState(true);
	const [errorPermission, setErrorPermission] = useState(false);
	const [isBusinessCategories, setIsBusinessCategories] = useState(true);
	const [businessCategories, setBusinessCategories] = useState<IBusinessCategory[]>([]);
	const [sidenavClassName, setSidenavClassName] = useState<sidenavClassNameType>(lastSidenavClassName);

	useEffect(() => {
		onLoad();
	}, []);

	useEffect(() => {
		if (isAuthenticated) {
			// auto redirection with token expiration
			checkExpiredToken(userHasAuthenticated, history);

			if (!isAuthenticating) {
				onLoad();
			}
		}
	}, [isAuthenticated]);

	async function onLoad() {
		try {
			const org = getOrg();
			if (localStorage.getItem('token')) {
				await checkOrgsTokenUser(org);
				await fetchUserPermissions();
				await getBusinessCategories();
				await fetchOrganizationSettings(org);
			} else {
				handleLogout(userHasAuthenticated, history);
			}
			setIsAuthenticating(false);

			if (localStorage.getItem('isAuthenticated') === 'false') {
				handleLogout(userHasAuthenticated, history);
			}
		} catch (e) {
			// rest of existing code omitted
			setIsAuthenticating(false);
		}
	}

	async function fetchOrganizationSettings(organizationCode: string) {
		const referenceListSyncs = await getOrganizationCachedSettings(
			organizationCode,
			'references.reference_list_sync',
			async () => {
				return await getRuleset();
			}
		);

		setUserRights(referenceListSyncs);
	}

	async function checkOrgsTokenUser(org = null, checkConnect = true) {
		const user = getUserConnected();

		// Check if user is still connected in the new pool
		try {
			if (checkConnect) {
				let access = await retrieveOrgsService().getAlls({
					organization: org,
				});
				if (access.data.connected_at && user) {
					setInitials(user.first_name.charAt(0) + user.last_name.charAt(0));
					setUser(user);
					userHasAuthenticated(true);
					localStorage.setItem('isAuthenticated', true);
				} else {
					throw 'not connected';
				}
			} else {
				setInitials(
					user.first_name?.charAt(0) && user.last_name?.charAt(0)
						? user.first_name.charAt(0) + user.last_name.charAt(0)
						: 'UN'
				);
				setUser(user);
				userHasAuthenticated(true);
				localStorage.setItem('isAuthenticated', true);
			}
		} catch (err) {
			userHasAuthenticated(false);
			localStorage.setItem('isAuthenticated', false);
		}
	}

	// get Business Categories
	const getBusinessCategories = () => {
		setIsBusinessCategories(true);
		getBusinessCategoryService().then(
			(res) => {
				setBusinessCategories(res?.data || []);
				setIsBusinessCategories(false);
			},
			(err) => {
				setBusinessCategories([]);
				setIsBusinessCategories(false);
			}
		);
	};

	const fetchUserPermissions = async () => {
		setIsLoadingPermission(true);
		return getMyPermissions().then(
			(res) => {
				setMyPermissions(res.data);
				setIsLoadingPermission(false);
			},
			(err) => {
				setMyPermissions(null);
				setIsLoadingPermission(false);
				setErrorPermission(true);
			}
		);
	};

	if (isAuthenticating || (isAuthenticated && isBusinessCategories)) {
		return <Loader />;
	}

	if (
		// if authenticated and an error occured in get permissions
		(isAuthenticated && errorPermission) ||
		// if get permission success and still have not a permission
		(!isLoadingPermission && !myPermissions)
	) {
		return <GlobalError translate={translate} />;
	}

	return (
		<NotificationProvider>
			<ReferenceListProvider>
				<ToastProvider>
					<ClearToasts history={history} />
					<ModalProvider>
						<userContext.Provider
							value={{
								isAuthenticated,
								userHasAuthenticated,
								checkOrgsTokenUser,
								fetchOrganizationSettings,
								getOrg,
								handleLogout: () => {
									handleLogout(userHasAuthenticated, history);
								},
								userInitials,
								user,
								userRigths,
								myPermissions,
								isLoadingPermission,
								businessCategories,
							}}
						>
							<sideNavContext.Provider value={{ className: sidenavClassName }}>
								<NotificationConsumer>
									{(notificationConsumerProps) => (
										<>
											{isAuthenticated && myPermissions && (
												<Sidebar
													{...notificationConsumerProps}
													user={user}
													sidenavClassName={sidenavClassName}
													setSidenavClassName={setSidenavClassName}
												/>
											)}

											<Suspense fallback={<Loader />}>
												<Routes {...notificationConsumerProps} />
											</Suspense>
										</>
									)}
								</NotificationConsumer>
							</sideNavContext.Provider>
						</userContext.Provider>
						<ModalConsumer>
							{({ modals, popModal }) =>
								modals.map((m: any, index) =>
									React.cloneElement(m, {
										key: m.key || index,
										show: true,
										visible: true,
										onHide: popModal,
									})
								)
							}
						</ModalConsumer>
					</ModalProvider>
				</ToastProvider>
			</ReferenceListProvider>
		</NotificationProvider>
	);
};

export default App;
