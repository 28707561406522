// utils
import { getApiPrefix, server } from 'src/utils/api-prefix.helper';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export default {
	referenceData: false,
	iconName: 'Reporting',
	url: `${serverUrl}reporting/reports`,
	noParams: true,
	path: '/reports',
	pageTitle: {
		list: 'Reports',
		action: {
			create: 'New Report',
			edit: 'Edit Report',
			execute: 'Execute Report',
		},
	},
	columns: [
		{
			headerName: 'Name',
			field: 'name',
		},
		{
			headerName: 'Type',
			field: 'type',
		},
		{
			headerName: 'Created at',
			field: 'created_at',
			type: 'datetime',
		},
		{
			headerName: 'Updated at',
			field: 'updated_at',
			type: 'datetime',
		},
	],
};
