import _ from 'lodash';
import DataService from 'src/utils/DataService';
import configWorkflow from 'ui/modules/Core/config/workflow.config';
import { data, getApiPrefix, ingestWorkflow, server } from 'src/utils/api-prefix.helper';
import { IPreviewPayload } from '../types/interfaces/dataset.interface';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;

export function getWorkflowService(params: string) {
	return new DataService({
		url: `${configWorkflow.url}${params}`,
		urlParams: '',
		token: true,
	}).getAll();
}

export function updateWorkflowService(id: string, payload: any, params: string = '') {
	return new DataService({
		url: `${serverUrl}${data}/workflows`,
		urlParams: params,
		token: true,
	}).update(id, payload);
}

export function getOneWorkflow(params: string, workflow_id: string) {
	return new DataService({
		url: `${configWorkflow.url}/${workflow_id}${params}`,
		urlParams: '',
		token: true,
	});
}

export function runPayloadService(datasetCode: string, type: string, date: string) {
	return new DataService({
		url: `${serverUrl}${ingestWorkflow}/datasets/${datasetCode}/payloads/${type}/${date}/run`,
		urlParams: '',
	});
}

export function loadPayloadService(datasetCode: string) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/history`,
		urlParams: '',
	}).getAll();
}

export function loadWorkflowExecutionState(datasetCode: string) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/state`,
		urlParams: '',
	}).getAll();
}

export function executeEmailMatchService(datasetCode: string) {
	return new DataService({
		url: `${serverUrl}${ingestWorkflow}/datasets/${datasetCode}/matching-emails/search`,
		urlParams: '',
	});
}

export function subscribeWorkflow(datasetCode: string) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/subscribe`,
		urlParams: '',
	}).create({});
}

export function endWorkflowExecutionService(datasetCode: string, payload: { workflow_id: string }) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/stop`,
		urlParams: '',
	}).create(payload);
}

export function executeWorkflowService() {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/execute`,
		urlParams: '',
	});
}

export function retrieveIssuesService(datasetCode: string, step: string, payload: IPreviewPayload) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/steps/${step}/errors`,
		urlParams: '',
	}).create(payload);
}

export function getWorkflowSettingService(workflowId: string, payload: any = null) {
	if (payload) {
		return new DataService({
			url: `${configWorkflow.ingestWorkflowUrl}/settings/${workflowId}`,
			urlParams: '',
		}).create(payload);
	} else {
		return new DataService({
			url: `${configWorkflow.ingestWorkflowUrl}/settings`,
			urlParams: '',
		}).getClean(workflowId);
	}
}

export function discardDatasetService(datasetCode: string, date: string) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/payloads/${date}/discard`,
		urlParams: '',
	}).deleteAll();
}

export function getWorkflowPreviewSteps(datasetCode: string, step: string, payload: IPreviewPayload) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/datasets/${datasetCode}/steps/${step}/preview`,
		urlParams: '',
	}).create(payload);
}

export function loadHistoryDataService(payload: any) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/workflow-loader-history`,
		urlParams: '',
	}).create(payload);
}

export function downloadPayload(payloadKey: string, format: string) {
	return new DataService({
		url: `${serverUrl}${data}`,
		urlParams: !format ? payloadKey : `${payloadKey}?format=${format}`,
	}).getAll();
}

type IExecuteOneEmailBody = {
	email_attachment_id: string;
	email_id: string;
};

export function scheduleWorkflowImmediately(type: string, workflow_id: string, body: IExecuteOneEmailBody | null) {
	const url = `${configWorkflow.ingestWorkflowUrl}/${
		type === 'email' ? `workflows/${workflow_id}/replay-last-email` : 'schedule-immediately'
	}`;
	const payload = type === 'email' ? { workflow_id, ...(body || {}) } : { id: workflow_id };

	return new DataService({ urlParams: [] }).getRequestService().post(url, payload);
}

export const executeAllMatchingWorkflowsService = async (payload: any) => {
	return await new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/replay-email`,
		urlParams: '',
	}).post(payload);
};

export const executeWorkflowOnAttachmentService = async (payload: any) => {
	return await new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/execute-workflow-on-attachment`,
		urlParams: '',
	}).create(payload);
};

export const getWorkflowMetadataService = async (workflow_id: string) => {
	return await new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/workflows/${workflow_id}/metadata`,
		urlParams: '',
	}).getAll();
};

export const saveWorkflowMetaDataService = async (workflow_id: string, payload: any) => {
	return await new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/workflows/${workflow_id}/metadata`,
		urlParams: '',
	}).create(payload);
};

export function ignoreHealthcheckViolations(id: string) {
	return new DataService({
		url: `${configWorkflow.ingestWorkflowUrl}/workflows/${id}/ignore-healthcheck`,
		urlParams: '',
		token: true,
	}).put();
}
