import { History } from 'history';
import { compact, find, includes, map, startsWith } from 'lodash';
import { getItem } from 'ui/components/Nav/Sidenav/helpers/navItem.helper';
import { sidebarConfig } from 'ui/components/Nav/Sidenav/config/navItems.config';
import { IPermissions, IUser } from 'ui/modules/Core/types/interfaces/user.interface';
import { INavItemProps } from 'ui/components/Nav/Sidenav/types/interfaces/sidenav.interface';
import { IBusinessCategory } from 'ui/modules/Core/types/interfaces/business-category.interface';
import configInterface from 'src/interface.config';
import configReferences from 'ui/modules/References/config/references.config';

// get navItems
export const getNavItems = (
	history: History,
	translate: (text: string) => string,
	user: IUser,
	isAuthenticated: boolean,
	myPermissions: IPermissions,
	businessCategories: IBusinessCategory[],
	inlineCollapsed: boolean
) => {
	const NavItems = sidebarConfig.map((item: INavItemProps) => {
		// map business categories under dashboard
		if (item.key === 'dashboard') {
			const children = (businessCategories || [])
				.filter((businessCategory) => businessCategory.dashboard_count > 0)
				.map((businessCategory) => {
					return {
						key: `/dashboard/${businessCategory.code}`,
						description: businessCategory.name,
						path: `/dashboard/${businessCategory.code}`,
						icon: businessCategory?.icon_name || 'Dashboard',
						is_child: true,
						permissionRecord: ['dashboard'],
					};
				});
			if (children.length) {
				item.children = children;
			}
		}

		// Default case for other items
		const parentItem = getItem(
			inlineCollapsed,
			item,
			history,
			translate,
			user,
			isAuthenticated,
			myPermissions
		) as any;

		if (!item.children || item.key === 'reference_data') return parentItem;
		// Handle "internal_configuration" item
		if (item.key === 'internal_configuration')
			return handleInternalConfigurationItem(parentItem, translate, inlineCollapsed);
		// Handle rest of items
		const children = parentItem?.children?.[0]?.children;
		const compactChildren = children ? compact(children) : undefined;
		if (!parentItem || !parentItem?.children) return null;

		return {
			...parentItem,
			children: [
				{
					...parentItem?.children[0],
					children: compactChildren,
				},
			],
		};
	});
	const res: INavItemProps[] = compact(NavItems);
	const items = inlineCollapsed
		? res
		: res.map((el) => ({
				...el,
				label: translate(el.description),
				...(el.children?.length
					? el.key === 'internal_configuration'
						? { children: el.children }
						: { children: el.children[0].children }
					: {}),
			}));

	return items;
};

// get default menuKey
export const getDefaultMenuKey = (items: INavItemProps[], inlineCollapsed: boolean) => {
	const currentPath = window.location.pathname;
	const referencesPaths = Object.keys(configReferences);
	let currentItem: string | null = null;

	map(items, (it) => {
		// get active parent item
		if (startsWith(currentPath, it.path)) {
			currentItem = it.key;
		}

		if (referencesPaths.some((key) => startsWith(currentPath, (configReferences as any)[key].path))) {
			currentItem = 'reference_data';
		}

		const allChildren = (inlineCollapsed ? it?.children?.[0]?.children : it.children) || [];
		if (allChildren?.length) {
			const child = find(allChildren, (children) => {
				if (
					Object.values(configInterface).some((value) => currentPath.includes(value)) ||
					currentPath?.includes(`${children.path}`)
				) {
					return startsWith(currentPath, children.path);
				}

				return includes(children.path, currentPath) && startsWith(currentPath, children.path);
			});

			if (child) {
				currentItem = child.key;
			}
		}
	});
	return currentItem || [];
};

// Helper function to recursively flatten children (needed for sub-menu with sub-children)
const flattenChildren = (children: any[] = []): any[] => {
	return children.reduce((acc, child) => {
		if (!child) return acc;
		const { children: nestedChildren, ...rest } = child;
		return [...acc, rest, ...(nestedChildren ? flattenChildren(nestedChildren) : [])];
	}, []);
};
// Helper to handle the "internal_configuration" item
const handleInternalConfigurationItem = (
	parentItem: any,
	translate: (text: string) => string,
	inlineCollapsed: boolean
): any => {
	if (parentItem?.children?.length > 0) {
		// Flatten all nested children
		const flattenedChildren = flattenChildren(parentItem.children);
		// Filter out the unwanted "internal_configuration-title" item
		const filteredChildren = flattenedChildren.filter((child) => child.key !== 'internal_configuration-title');
		// Handle inlineCollapsed case
		if (inlineCollapsed) {
			return {
				...parentItem,
				children: [
					{
						key: `${parentItem.key}-title`,
						label: translate(parentItem.description),
						type: 'group',
						children: filteredChildren,
					},
				],
			};
		}
		// Fall back to expanded case
		return {
			...parentItem,
			children: filteredChildren,
		};
	}
	return parentItem;
};
