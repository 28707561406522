import React, { Component } from 'react';
import { faUserRobot } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReferenceField from 'ui/components/ReferenceField/ReferenceField';
import config from 'config';
import { get } from 'lodash';

export default class referenceRenderer extends Component {
	render() {
		let colName = this.props.colDef.field;
		const reference = get(this.props.data, colName);

		if (!reference) return null;

		let value = reference.name;

		// System user
		if (this.props.colDef.referenceType == 'user' && reference.id == config.systemUser) {
			return (
				<span>
					<FontAwesomeIcon icon={faUserRobot} className="mr-1" /> {reference.first_name}
				</span>
			);
		}

		if (this.props.colDef.referenceType) {
			colName = this.props.colDef.referenceType;
			if (this.props.colDef.referenceType == 'user') value = `${reference.first_name} ${reference.last_name}`;
			else value = reference.name;
		}

		return config.records[colName] ? (
			<span>
				<ReferenceField
					value={value}
					url={`${config.records[colName].path}/${
						config.records[colName].navigateByCode ? reference.code : reference.id
					}`}
				/>
			</span>
		) : (
			reference.name
		);
	}
}
